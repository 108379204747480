import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Image from 'gatsby-image'
import slugify from 'slugify'

const StoryCard = ({ title }) => {
  const slug = '/' + slugify(title);
  const { illustrations } = useStaticQuery(graphql`
    query {
      illustrations: allImageSharp(filter: {fixed: {originalName: {glob: "illustration_*"}}}) {
        nodes {
          fixed(width: 200, height: 125, fit: COVER, cropFocus: NORTH) {
            originalName
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const illustration = illustrations.nodes.find(({ fixed: { originalName } }) => originalName === `illustration_${title}.jpg`)
  if (!illustration) {
    console.log(JSON.stringify(illustrations.nodes.map(({ fixed: { base64, ...rest } }) => rest), null, 2))
    throw new Error(`No illustration found for story ${title}`)
  }

  return (
    <Link className="stories-link" to={slug}>
      <div className="stories-card">
        <div className="stories-thumbnail"><Image fixed={illustration.fixed} /></div>
        <div className="stories-title">{title}</div>
      </div>
    </Link>
  )
}

export default StoryCard
