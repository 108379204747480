import React from 'react'
import { graphql } from "gatsby"
import Layout from '../components/layout'
import SEO from '../components/seo'
import Card from '../components/card'
import docx from '../lib/docx'

const StoriesPage = ({ data }) => {
  const stories = docx(data);
  return (
    <Layout>
      <SEO title="Stories" />
      <div className='stories-container'>
        {stories.map(({ title }) =>
          <Card key={title} title={title} />
        )}
      </div>
    </Layout >
  )
}

export default StoriesPage

export const query = graphql`
  query StoriesQuery {
    allDocx(filter: {name: {glob: "stories_*"}}) {
      nodes {
        name
        content
      }
    }
  }
`